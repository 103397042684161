globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"portal@823de13d36134ffb57e2a6c92728a3c2f93e04ec"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import {
  browserTracingIntegration,
  init,
  replayIntegration,
} from '@sentry/nextjs';

import { deploymentType, sentryDSN, sentryEnabled } from './src/config';

init({
  dsn: sentryDSN,
  integrations: [browserTracingIntegration(), replayIntegration()],
  enabled: sentryEnabled,
  environment: deploymentType,
  // To save cost, sample half the traffic in production, and 10% in staging
  tracesSampleRate: deploymentType === 'PRODUCTION' ? 0.5 : 0.1,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  ignoreErrors: ['ResizeObserver'],
});
